import storefrontInstance from '@/api/instances/storefront';
import {ProductDeliveryStatus} from '@/constants/product';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';
import {DateFormats} from '@/constants/date-formats';

const ShipmentsApi = {
  async getCart() {
    const response = await storefrontInstance.get('/checkout/multi/shipment/items/');
    let populatedEntries = [];
    let data = response?.data?.data;
    data.entries.map((entry) => {
      for (const item in entry.subtotalsByWarehouse) {
        let entryCopy = {
          ...entry,
          warehouse: item,
        };
        populatedEntries.push(entryCopy);
      }
    });
    return {
      ...data,
      populatedEntries,
    };
  },

  async getShipmentsInfo() {
    const response = await storefrontInstance.get('/checkout/multi/shipment/shipmentsInfo/');
    return response?.data?.data;
  },

  async getTotals() {
    const response = await storefrontInstance.put('/cart/totals/', {});
    return response?.data?.data;
  },

  async getTotalsWithFreight(shipmentsInfo, entries, warehouses) {
    const response = await storefrontInstance.put(`/freight/`, freightDTO(shipmentsInfo, entries, warehouses));

    return response?.data?.data;
  },

  async getAppleLabels(variantCodesList = []) {
    const response = await storefrontInstance.post('/v1/api/batch/price/all/', {variantCodesList: variantCodesList});
    return response?.data?.data;
  },

  async updateCartEntries({sku, lineNote, alreadySoldToConsumer, qty, cartEntryPk}) {
    const response = await storefrontInstance.put(`/cart/entries/`, {
      cartEntries: [{sku, lineNote, alreadySoldToConsumer, qty, cartEntryPk}],
    });
    return response?.data?.data;
  },

  async updateAppleLabels(items) {
    const dto = buildUpdateAppleLabelsDTO(items);
    const response = await storefrontInstance.patch('/apple-labels/', dto);
    return response;
  },

  async updateShipmentsInfo(shipmentsInfo) {
    const response = await storefrontInstance.put(
      '/checkout/multi/shipment/shipmentsInfo/',
      updateShipmentsInfoDTO(shipmentsInfo)
    );
    return response;
  },

  async updateShipmentsItems(entries, warehouses) {
    const response = await storefrontInstance.put(
      '/checkout/multi/shipment/items/',
      updateShipmentsItemsDTO(entries, warehouses)
    );
    return response;
  },
};

function updateShipmentsInfoDTO(shipmentsInfo) {
  let compiledData = {};

  for (const productType in shipmentsInfo) {
    const deliveryDate = shipmentsInfo[productType]?.delivery?.date || getTodaysDate();

    compiledData[productType] = {
      customerPO: shipmentsInfo[productType]?.customerPO,
      holdOptions: {
        selected: shipmentsInfo[productType]?.holdOptions?.selected,
        note: shipmentsInfo[productType]?.holdOptions?.note,
      },
      delivery: {
        port: shipmentsInfo[productType]?.delivery?.port,
        date: new Date(deliveryDate).toISOString(),
      },
      shippingOptions: {
        selected: shipmentsInfo[productType]?.shippingOptions?.selected,
      },
    };
  }
  return {
    shipments: {
      ...compiledData,
    },
  };

  function getTodaysDate() {
    return dayjs(new Date()).format(DateFormats.DEFAULT);
  }
}

function freightDTO(shipmentsInfo, entries, warehouses) {
  const cloneEntries = structuredClone(entries);
  let entriesSubtotalsData = entriesSubtotalsDTO(cloneEntries, warehouses).map((entry) => {
    return {
      product: {code: entry.product.code},
      groupingType: entry.groupingType,
      subtotalsByWarehouse: entry.subtotalsByWarehouse,
    };
  });

  let shipmentsData = {};
  for (const productType in shipmentsInfo) {
    shipmentsData[productType] = {
      shippingOptions: {
        selected: shipmentsInfo[productType]?.shippingOptions?.selected,
      },
    };
  }

  return {
    shipments: {
      ...shipmentsData,
    },
    entries: uniqBy(entriesSubtotalsData, 'product.code'),
  };
}

function updateShipmentsItemsDTO(entries, warehouses) {
  let entriesSubtotalsData = entriesSubtotalsDTO(entries, warehouses).map((entry) => {
    return {
      entryPK: entry.entryPK,
      subtotalsByWarehouse: entry.subtotalsByWarehouse,
    };
  });

  return {
    entries: uniqBy(entriesSubtotalsData, 'entryPK'),
  };
}

function entriesSubtotalsDTO(entries, warehouses) {
  return entries.map((entry) => {
    const primaryWarehouse = warehouses.find((warehouse) => warehouse.isPrimary).id;

    if (entry.subtotalsByWarehouse[primaryWarehouse] === undefined) {
      entry.subtotalsByWarehouse[primaryWarehouse] = {
        availableQuantity: 0,
        backorderQuantity: 0,
        quantity: 0,
      };
    }

    if (entry.warehouse === primaryWarehouse) {
      entry.subtotalsByWarehouse[primaryWarehouse].availableQuantity =
        entry.subtotalsByWarehouse[primaryWarehouse].quantity;
      entry.subtotalsByWarehouse[primaryWarehouse].backorderQuantity = 0;
    }
    if (entry.warehouse === ProductDeliveryStatus.BACKORDERED) {
      entry.subtotalsByWarehouse[primaryWarehouse].backorderQuantity +=
        entry.subtotalsByWarehouse[entry.warehouse].quantity;
    }
    if (
      entry.isBackorderInPrimary &&
      entry.warehouse !== primaryWarehouse &&
      entry.warehouse !== ProductDeliveryStatus.BACKORDERED
    ) {
      entry.subtotalsByWarehouse[primaryWarehouse].backorderQuantity +=
        entry.subtotalsByWarehouse[entry.warehouse].quantity;
      entry.subtotalsByWarehouse[primaryWarehouse].availableQuantity =
        entry.subtotalsByWarehouse[primaryWarehouse].quantity;
      entry.subtotalsByWarehouse[entry.warehouse].availableQuantity = 0;
    }
    if (!entry.isBackorderInPrimary) {
      entry.subtotalsByWarehouse[entry.warehouse].availableQuantity =
        entry.subtotalsByWarehouse[entry.warehouse].quantity;
      entry.subtotalsByWarehouse[entry.warehouse].backorderQuantity = 0;
    }
    return entry;
  });
}

function buildUpdateAppleLabelsDTO(items = []) {
  return items.map(
    ({
      id,
      sku,
      retailLabelStatus: status,
      retailLabelProductId: partNumber,
      retailLabelDescription: description,
      retailLabelPrice: price,
    }) => {
      return {
        id,
        sku,
        status,
        partNumber,
        description,
        price,
      };
    }
  );
}

export {ShipmentsApi};
