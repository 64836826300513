import SizeChartApi from '@/api/size-chart';
import Vue from 'vue';

const mutationTypes = Object.freeze({
  SET_PRICE_DATA: 'setPriceData',
  SET_RPA_VIEW_IN_STOCK: 'setRpaViewInStock',
  SET_PDP_DETAILS_LOADED: 'setPdpDetailsLoaded',
  SET_REQUESTED_PRODUCT_CODE: 'setRequestedProductCode',
  SET_SEARCH_RESULT_REFERENCE: 'setSearchResultReference',
  SET_SIZE_CHART_DATA: 'setSizeChartData',
  SET_PDP_SKU: 'setPdpSku',
  SET_MECHANIC_NOTES: 'setMechanicNotes',
});

const pdpModule = {
  namespaced: true,

  state: {
    pdpSku: 0,
    priceData: {},
    isBike: false,
    rpaViewInStock: false,
    pdpDetailsLoaded: false,
    requestedProductCode: 0,
    searchResultReference: 0,
    electraAccessoryFinderToggle: false,
    pdpFunctionsMapping: {},
    isElectraBrand: false,
    activeTab: '',
    primaryProductUrl: '',
    primaryProductName: '',
    primaryProductMetaData: [{key: '', value: ''}],
    productQuantity: null,
    sizeChartData: null,
    isBuyingZoneBundleDialogVisible: false,
    isGridBundleDialogVisible: false,
    variantCode: '',
    isECommAvailable: false,
    isLocallyActive: false,
    category: {},
    mechanicNotes: [],
    pdpBaseProductCode: 0,
    product: null,
    initAttributeSelection: {},
  },
  getters: {
    getActiveTab(state) {
      return state.activeTab;
    },
  },

  mutations: {
    setPdpSku(state, pdpSku) {
      Vue.set(state, 'pdpSku', pdpSku);
    },

    setIsECommAvailable(state, payload) {
      Vue.set(state, 'isECommAvailable', payload);
    },

    setPriceData(state, payload) {
      Vue.set(state, 'priceData', payload);
    },

    setIsBike(state, payload) {
      state.isBike = payload;
    },

    setIsElectraBrand(state, payload) {
      state.isElectraBrand = payload;
    },

    setElectraAccessoryFinderToggle(state, payload) {
      state.electraAccessoryFinderToggle = payload;
    },

    setPdpFunctionsMapping(state, payload) {
      state.pdpFunctionsMapping = payload;
    },

    setRpaViewInStock(state, payload) {
      state.rpaViewInStock = payload;
    },

    setPdpDetailsLoaded(state, payload) {
      state.pdpDetailsLoaded = payload;
    },

    setRequestedProductCode(state, payload) {
      state.requestedProductCode = payload;
    },

    setSearchResultReference(state, payload) {
      state.searchResultReference = payload;
    },
    setActiveTab(state, payload) {
      Vue.set(state, 'activeTab', payload);
    },
    setPrimaryProductUrl(state, payload) {
      state.primaryProductUrl = payload;
    },

    setPrimaryProductName(state, payload) {
      state.primaryProductName = payload;
    },

    setPrimaryProductMetaData(state, payload) {
      state.primaryProductMetaData = payload;
    },

    setProductQuantity(state, payload) {
      state.productQuantity = payload;
    },
    setSizeChartData(state, payload) {
      state.sizeChartData = payload;
    },
    // Common mutation for two bundle modals. One for buying zone, other for sku-grid(both on pdp)
    setBundleDialogVisibility(state, payload) {
      if (payload.type === 'BUYING') {
        Vue.set(state, 'isBuyingZoneBundleDialogVisible', payload.isVisible);
      }

      if (payload.type === 'GRID') {
        Vue.set(state, 'isGridBundleDialogVisible', payload.isVisible);
      }
    },

    setIsVariantCode(state, payload) {
      state.variantCode = payload;
    },

    setIsLocallyActive(state, payload) {
      state.isLocallyActive = payload;
    },

    setCategory(state, payload) {
      state.category = payload;
    },
    [mutationTypes.SET_MECHANIC_NOTES](state, payload) {
      state.mechanicNotes = payload ?? [];
    },
    setPdpBaseProductCode(state, payload) {
      state.pdpBaseProductCode = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setInitAttributeSelection(state, payload) {
      state.initAttributeSelection = payload;
    },
  },

  actions: {
    setVariantPdpSku({commit}, pdpSku) {
      commit(mutationTypes.SET_PDP_SKU, pdpSku);
    },

    setVariantPrice({commit}, priceData) {
      commit(mutationTypes.SET_PRICE_DATA, priceData);
    },

    setRpaViewInStockModalValue({commit}, viewInStockValue) {
      commit(mutationTypes.SET_RPA_VIEW_IN_STOCK, viewInStockValue);
    },
    updatePdpDetailsLoaded({commit}, pdpDetailsLoaded) {
      commit(mutationTypes.SET_PDP_DETAILS_LOADED, pdpDetailsLoaded);
    },
    updateRequestedProductCode({commit}, requestedProductCode) {
      commit(mutationTypes.SET_REQUESTED_PRODUCT_CODE, requestedProductCode);
    },
    updateSearchResultReference({commit}, searchResultReference) {
      commit(mutationTypes.SET_SEARCH_RESULT_REFERENCE, searchResultReference);
    },
    async fetchSizeChartData({commit}, productId) {
      try {
        const sizeChartData = await SizeChartApi.getSizeChartData(productId);
        commit(mutationTypes.SET_SIZE_CHART_DATA, sizeChartData);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    setInitAttributeSelection({commit}, selection) {
      commit('setInitAttributeSelection', selection);
    },
  },
};

export default pdpModule;
