import {PaymentProvider} from '@/constants/payment-provider';
import BaseProvider from '@/utils/checkout/payment-providers/base-provider';

class IdealProvider extends BaseProvider {
  constructor(options) {
    super(options);
    const {bankName, billingAddress} = options;

    this.bankName = bankName;
    this.billingAddress = billingAddress;

    this.providerCode = PaymentProvider.CYBERSOURCE_IDEAL;
  }

  async initializePayment() {
    const data = {
      billingAddress: this.billingAddress,
      paymentProviderCode: this.providerCode,
      paymentOption: this.bankName,
    };
    return this.submitPaymentInitForm(data);
  }
}

export default IdealProvider;
