import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import {UserStatus} from '@/constants/backend';
import {UnitTypes, RideTemperatureUnits} from '@/constants/unit-types';
import {
  IconCardTypes,
  CybersourceApplePayPaymentMethodCode,
  Gr4vyApplePayPaymentMethodCode,
  KCPPaymentMethodCode,
} from '@/constants/checkout.js';

const backendModule = {
  namespaced: true,
  state: {
    b2b: false,
    isAccountConsumerToggleAccessEnabled: true,
    b2cHideBikeModelYearToggle: false,
    cartCode: '',
    cartGuid: '',
    currencyIso: '',
    currentB2BUnit: 'b2c',
    customVariables: [],
    language: '',
    pageTitle: '',
    hostUrl: '',
    userId: '',
    unitId: 'b2c',
    pageCategory: '',
    isConsumerFriendlyMode: false,
    cartToggle: true,
    isAdmin: window.ACC?.isAdmin ?? false,
    isB2BPriceToggle: false,
    isB2Bsite: false,
    contextPath: window.ACC?.config?.contextPath ?? '',
    encodedContextPath: window.ACC?.config?.encodedContextPath ?? '',
    locale: '',
    isB2BPurchasable: window.ACC?.isB2BPurchasable ?? false,
    appleLabelAccess: window.ACC?.appleLabelAccess,
    maxCartItems: window.ACC?.maxCartItems ?? 950,
    isDistributor: window.ACC?.isDistributor ?? false,
    isEmployee: window.ACC?.isEmployee ?? false,
    containerToDelete: null,
    isSpectrumEnabled: false,
    isUserLoggedIn: false,
    trekBrandNavURL: window.ACC?.config.trekBrandNavURL ?? '',
    hasCustomWaterBottleRestriction: false,
    cdnStaticFilesRoot: '',
    CSRFToken: '',
    geolocation: {},
    isLoadingLocation: false,
    supportedPaymentMethods: [],
    isProduction: false,
  },
  mutations: {
    setConsumerFriendlyMode(state, value) {
      state.isConsumerFriendlyMode = value;
    },
    setContainerToDelete(state, value) {
      state.containerToDelete = value;
    },
    setBackendProps(state, payload) {
      Object.entries(payload).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
    setIsSpectrumEnabled(state, value) {
      state.isSpectrumEnabled = value;
    },
    setHasCustomWaterBottleRestriction(state, value) {
      state.hasCustomWaterBottleRestriction = value;
    },
    setIsLoadingLocation(state, value) {
      state.isLoadingLocation = value;
    },
  },

  getters: {
    isRestrictedCustomer(state) {
      return state.isEmployee || state.isDistributor;
    },

    unitAbbreviation(state) {
      if (state.preferredUnit === UnitTypes.IMPERIAL.milesString) {
        return UnitTypes.IMPERIAL.milesAbbreviation;
      } else {
        return UnitTypes.METRIC.kilometersAbbreviation;
      }
    },
    userTemperatureUnit(state) {
      if (state.preferredUnit === UnitTypes.IMPERIAL.milesString) {
        return RideTemperatureUnits.FAHRENHEIT;
      } else {
        return RideTemperatureUnits.CELSIUS;
      }
    },
    occUrlParams(state) {
      return {
        userId: state.isUserLoggedIn ? UserStatus.CURRENT_USER : UserStatus.ANONYMOUS_USER,
        cartId: state.isUserLoggedIn ? state.cartCode : state.cartGuid,
      };
    },
    cartParams(state) {
      return {
        lang: state.language,
        curr: state.currencyIso,
      };
    },
    getSupportedPaymentMethodCodes(state) {
      let codes = [];
      state.supportedPaymentMethods.forEach((type) => {
        codes.push(type.code);
      });
      return codes;
    },
    getSupportedCardTypeCodes(state) {
      let codes = [];
      state.supportedPaymentMethods.forEach((type) => {
        if (
          type.code !== CybersourceApplePayPaymentMethodCode &&
          type.code !== Gr4vyApplePayPaymentMethodCode &&
          type.code !== KCPPaymentMethodCode &&
          !IconCardTypes[type]
        )
          codes.push(type.code);
      });
      return codes;
    },
    countryCode(state) {
      return state.language?.split('_')?.[1].toLocaleLowerCase();
    },
  },

  actions: {
    async getSupportedPaymentMethods({state}) {
      try {
        const response = await occInstance.get(`cardtypes?lang=${state.language}&curr=${state.currencyIso}`);
        state.supportedPaymentMethods = response.data.cardTypes;
      } catch (error) {
        console.error(error);
      }
    },
    toggleConsumerFriendlyMode({commit, state}) {
      let value = !state.isConsumerFriendlyMode;
      storefrontInstance
        .get(`/my-account/price-toggle?priceToggle=${value}`)
        .then(() => {
          commit('setConsumerFriendlyMode', value);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default backendModule;
