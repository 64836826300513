import {ShipmentsApi} from '@/api/shipments';
import {convertMapToArrayDeep, groupByDeep, sortList} from '@/utils/entries-helpers';
import {ProductTypeOrder} from '@/constants/product';
import {formatAppleData} from '@/utils/product-info';
import Vue from 'vue';

const shipmentsModule = {
  namespaced: true,

  state: {
    entries: [],
    warehousesSubtotals: null,
    warehouses: [],
    shipmentsInfo: {},
    appleLabels: {},
    areQuantitiesPristine: true,
    totals: null,
    freightTotals: null,
  },

  getters: {
    entriesTree(state) {
      return groupByDeep(
        state.entries.filter((entry) => entry.subtotalsByWarehouse[entry.warehouse].quantity > 0),
        (entry) => entry.groupingType,
        (entry) => entry.warehouse
      );
    },
    // VUE 2 do not support Map iteration inside v-for
    // Convert Map to Array to be able to render tree in template
    entriesListTree(_, getters) {
      return convertMapToArrayDeep(getters.entriesTree);
    },
    areQuantitiesUpdated(state) {
      return state.entries.some((entry) => {
        let sumQuantity = 0;

        // Compare total quantities of all entries
        Object.values(entry.subtotalsByWarehouse).forEach((subtotals) => {
          sumQuantity += subtotals.availableQuantity;
          sumQuantity += subtotals.backorderQuantity;
          if (subtotals.availableQuantity > 0 && subtotals.quantity !== subtotals.availableQuantity) {
            return true;
          }
        });
        return entry.quantityOrdered != sumQuantity;
      });
    },
    productTypes(state) {
      return [...new Set(state.entries.map((entry) => entry.groupingType))];
    },
    warehousesMap(state) {
      return state.warehouses.reduce((map, warehouse) => {
        map[warehouse.id] = warehouse;

        return map;
      }, {});
    },
    appleLabelAccess() {
      return {
        BIKE: ACC.appleLabelAccess.bikes,
        AFTERMARKET: ACC.appleLabelAccess.parts,
      };
    },
    variantCodesList(state) {
      return function (warehouseId, type) {
        return state.entries
          .filter((entry) => entry.warehouse === warehouseId && entry.groupingType === type)
          .map((item) => item.product.code);
      };
    },
  },

  mutations: {
    setEntries(state, payload) {
      const warehousesOrder = state?.warehouses.map((warehouse) => warehouse.id);
      const entries = sortList(
        payload,
        [warehousesOrder, (entry) => entry.warehouse],
        [ProductTypeOrder, (entry) => entry.groupingType]
      );

      state.entries = entries.map((item) => {
        return {
          ...item,
          isBackorderInPrimary: true,
        };
      });
    },

    setWarehousesSubtotals(state, payload) {
      state.warehousesSubtotals = payload;
    },
    setWarehouses(state, payload) {
      state.warehouses = payload.sort((w1, w2) => w1.weight - w2.weight);
    },
    setShipmentsInfo(state, payload) {
      state.shipmentsInfo = payload;
      for (const key in state.shipmentsInfo.shipments) {
        if (!state.shipmentsInfo.shipments[key].customerPO) {
          Vue.set(state.shipmentsInfo.shipments[key], 'customerPO', '');
        }
      }
    },
    setAppleLabels(state, payload) {
      state.appleLabels = payload;
    },
    updateShipmentDeliveryDate(state, {type, value}) {
      state.shipmentsInfo.shipments[type].delivery.date = value;
    },
    updateShippingOptions(state, {type, value}) {
      state.shipmentsInfo.shipments[type].shippingOptions.selected = value;
    },
    updateShipmentProperty(state, {type, property, value}) {
      state.shipmentsInfo.shipments[type][property] = value;
    },
    updateEntryProperty(state, {entry, property, value}) {
      const entryIndex = state.entries.findIndex(
        ({sku, warehouse}) => entry.sku === sku && entry.warehouse === warehouse
      );
      state.entries[entryIndex][property] = value;
    },
    resetBackorderState(state, payload) {
      state.entries.forEach((entry) => (entry.isBackorderInPrimary = payload));
    },
    setQuantitiesPristineState(state, payload) {
      state.areQuantitiesPristine = payload;
    },
    updateBackorderStatus(state, payload) {
      state.entries.forEach((entry) => {
        if (entry.warehouse === payload.warehouse) {
          entry.isBackorderInPrimary = payload.value;
        }
      });
    },
    setTotals(state, payload) {
      state.totals = payload;
    },
    setFreightTotals(state, payload) {
      state.freightTotals = payload;
    },
  },

  actions: {
    async fetchCart({commit}) {
      const response = await ShipmentsApi.getCart();

      commit('setWarehouses', response.warehouses);
      commit('setEntries', response.populatedEntries);
      commit('setWarehousesSubtotals', response.subtotals);
    },

    async fetchTotals({commit}) {
      const response = await ShipmentsApi.getTotals();

      commit('setTotals', response);
      commit('setFreightTotals', null);
    },

    async fetchTotalsWithFreight({commit, state}) {
      const response = await ShipmentsApi.getTotalsWithFreight(
        state.shipmentsInfo.shipments,
        state.entries,
        state.warehouses
      );

      commit('setFreightTotals', response);
    },

    async fetchShipmentsInfo({commit}) {
      const response = await ShipmentsApi.getShipmentsInfo();

      commit('setShipmentsInfo', response);
    },

    async fetchAppleLabels({commit, getters}, {warehouseId, type}) {
      let response = await ShipmentsApi.getAppleLabels(getters.variantCodesList(warehouseId, type));

      commit('setAppleLabels', formatAppleData(response, {uidProperty: 'code'}));
    },

    async updateShipmentsDetails(context, {shipments, entries, warehouses}) {
      const responseShipmentsInfo = await ShipmentsApi.updateShipmentsInfo(shipments);
      const responseShipmentsItems = await ShipmentsApi.updateShipmentsItems(entries, warehouses);

      const shipmentsInfoError = responseShipmentsInfo?.data?.meta?.feedback?.type !== 'SUCCESS';
      const shipmentsItemsError = responseShipmentsItems?.data?.meta?.feedback?.type !== 'SUCCESS';

      return !(shipmentsInfoError || shipmentsItemsError);
    },
  },
};

export default shipmentsModule;
