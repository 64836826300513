import {PaymentProvider} from '@/constants/payment-provider';
import BaseProvider from '@/utils/checkout/payment-providers/base-provider';

class KcpProvider extends BaseProvider {
  constructor(options) {
    super(options);
    const {billingAddress, installments} = options;

    this.billingAddress = billingAddress;
    this.installments = installments;

    this.providerCode = PaymentProvider.CYBERSOURCE_KCP;
  }

  async initializePayment() {
    const data = {
      billingAddress: this.billingAddress,
      paymentOption: this.providerCode,
      paymentProviderCode: this.providerCode,
    };
    return this.submitPaymentInitForm(data);
  }
}

export default KcpProvider;
